
import { Component, Vue } from 'vue-property-decorator'
import { OrderDetail } from '@/types/workOrder.d'
import OrderDetailDialog from './OrderDetailDialog.vue'

@Component({
  name: 'OrderManage',
  components: {
    OrderDetailDialog
  }
})
export default class OrderManage extends Vue {
  private searchInfo = {
    projectId: '',
    orderType: '',
    orderTitle: '',
    orderStatus: '',
    orderUserName: '',
    startTime: '',
    endTime: ''
  }

  private dateArr = []
  private statusList = ['待反馈', '待审核', '已完成', '已关闭']
  private tableData = {
    loading: false,
    tr: [
      {
        label: '工单编号',
        prop: 'orderId',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '归属项目',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '工单类型',
        prop: 'orderTypeName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '工单内容',
        prop: 'orderContent',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '工单截止日期',
        prop: 'orderDate',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'orderStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '当前处理人',
        prop: 'orderUserName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '创建时间',
        prop: 'ctime',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private total = 0
  private page = 1
  private size = 10
  private typeList = []
  private detailShow = false
  private handleType = '0' // 处理类型 4：查看 0：反馈 1：审核 2：转办 3：关闭
  private orderId = ''

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
  }

  getTypeList () {
    this.searchInfo.orderType = ''
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByList, {
      projectId: this.searchInfo.projectId,
      orderTypeStatus: '1'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.workOrder.selectWorkOrderByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  getStatusName (orderStatus: string) {
    return this.statusList[Number(orderStatus)]
  }

  dateChange (date: any) {
    if (date) {
      this.searchInfo.startTime = date[0]
      this.searchInfo.endTime = date[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
  }

  onDetailShow (row: OrderDetail, type: string) {
    if (type) {
      this.handleType = type
    } else {
      this.handleType = row.orderStatus || ''
    }
    this.orderId = row.orderId || ''
    this.detailShow = true
  }

  closeDialog () {
    this.detailShow = false
    this.getData()
  }
}
